<template>
  <button :disabled="disabled" class="btn" @click="$emit('click')">
    <template v-if="!loading">
      <slot></slot>
    </template>
    <img v-if="loading" src="@/assets/icons/spinner.svg" class="spinner" alt="spinner" />
    <component class="icon" :is="require(`@/assets/icons/${icon}.svg?inline`)" v-if="icon"></component>
  </button>
</template>

<script>
import Spinner from '@/assets/icons/spinner.svg?inline'

export default {
  components: {
    Spinner
  },
  props: {
    icon: String,
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>
.icon {
  margin-left: 15px;
}
.spinner {
  width: 22px;
  height: 22px;
}
</style>
