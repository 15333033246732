<template>
  <div class="form-item">
    <div class="d-flex justify-content-between">
      <label :for="id">{{ label }}</label>
      <slot name="right-label"></slot>
    </div>
    <input :disabled="disabled" :class="{'padding-right': appendContent}" :placeholder="placeholder" :value="value" @input="$emit('input', $event.target.value)"
           :autocomplete="autocomplete" :id="id" :type="type">
    <span v-if="appendContent" class="append-content">{{appendContent}}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    type: String,
    id: String,
    value: [String, Number],
    autocomplete: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    appendContent: [String, null],
    placeholder: String
  }
}
</script>

<style lang="scss" scoped>
.error {
  input {
    border: 2px solid var(--color-red-error);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

.append-content {
  position: absolute;
  bottom: 15px;
  right: 30px;
  font-size: 14px;
  color: var(--color-gray);
}

.padding-right {
  padding-right: 40px !important;
}
</style>
