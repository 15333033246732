import Vue from "vue";
import Vuex from 'vuex';
import {getUserFromToken, removeTokenData} from "@/services/auth-service";
import {router} from "@/router";
import {userRoles, userRolesTypes} from "@/utils/user-roles";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    user: null
  },
  mutations: {
    async setUser(state, token) {
      try {
        state.user = getUserFromToken(token);
      } catch (e) {
        removeTokenData();
        await router.push('/login')
      }
    }
  },
  getters: {
    getUserRoles(state) {
      if(!state.user) return null;

      return state.user.realm_access.roles.filter(item => userRolesTypes[item])
    },
    isCompanyAdmin(state, getters) {
      return getters.getUserRoles.includes(userRolesTypes.COMPANY_ADMIN)
    }
  }
})
