export const makeTheme = async () => {
  const theme = await getThemePresets()

  document.title = theme.appTitle
  setFavicon(theme.favicon)

  let root = document.querySelector(':root');
  root.style.setProperty('--color-primary', theme.primaryColor)
  root.style.setProperty('--color-secondary', theme.secondaryColor)
  root.style.setProperty('--color-hover', theme.hoverColor)
  root.style.setProperty('--color-hover-secondary', theme.secondaryHover)
}

export const getThemePresets = async () => {
  const themeEnv = process.env["VUE_APP_CONFIG"]
  const themePresets = await import(`./${themeEnv}.js`)
  return themePresets.default
}

const setFavicon = (favicon) => {
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = `/favicons/${favicon}`;
}
