import Vue from 'vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: createElement => createElement('img', {class: {'select--close': true}, attrs: {src: require('@/assets/icons/chevron-down.svg')}}),
  },
  Deselect: {
    render: createElement => createElement('img', {class: {'toggle': true}, attrs: {src: require('@/assets/icons/cross.svg')}}),
  },
});
Vue.component('v-select', vSelect);
