import jwtDecode from "jwt-decode";

export const storeTokensData = (response) => {
  localStorage.setItem('token', response.access_token);
  localStorage.setItem('refreshToken', response.refresh_token);

  let tokenDate = new Date();
  tokenDate.setSeconds(tokenDate.getSeconds() + response.expires_in)
  localStorage.setItem('expiresAt', tokenDate.toString());

  let refreshTokenDate = new Date();
  refreshTokenDate.setSeconds(refreshTokenDate.getSeconds() + response.refresh_expires_in);
  localStorage.setItem('refreshExpiresAt', refreshTokenDate.toString());
}

export const removeTokenData = () => {
  localStorage.clear();
}

export const getUserFromToken = (token) => {
  return jwtDecode(token);
}
