<template>
  <component class="icon" :is="require(`@/assets/icons/${name}.svg?inline`)"></component>
</template>

<script>
export default {
  props: ['name']
}
</script>

<style scoped>
.icon {

}
</style>
