<template>
  <table class="app-table">
    <thead>
      <tr>
        <th :width="headWidth(item.width)" v-for="(item, index) in head" :key="index">
          {{item.name}}
        </th>
      </tr>
    </thead>
    <tbody>
      <slot></slot>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    head: Array,
    options: Array
  },
  methods: {
    headWidth(width) {
      return  width ? width + '%' : 'auto';
    }
  }
}
</script>

<style lang="scss" scoped>
.app-table {
  width: 100%;
  border-spacing: 0;
  font-size: 14px;

  thead {
    background: var(--color-gray-xLight);
    border-radius: 30px;
    tr {
      height: 48px;
    }
    th {
      text-align: left;
      padding-left: 10px;
    }
    th:first-child{
      padding-left: 20px;
      border-radius:30px 0 0 30px;
    }

    th:last-child{
      border-radius:0 30px 30px 0;
    }
  }
  tbody {
    td {
      border-bottom: 2px solid var(--color-gray-xLight);
      padding: 13px 10px;
      font-weight: 400;
      font-size: 14px;
    }
    td:first-child {
      padding-left: 20px;
    }
  }
  .expanded {
    td {
      border-bottom: none !important;
    }
  }

  .link-cell {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .arrow {
    cursor: pointer;
    transition: all 0.2s;
  }

  .rotate-arrow {
    transform: rotate(90deg);
  }
}
</style>
