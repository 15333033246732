import VueRouter from "vue-router";
import Vue from 'vue';
Vue.use(VueRouter);

const routes = [
  {path: '/', meta: {auth: true}, component: () => import('@/views/dashboard'), children: [
      {path: '/', name: 'overview', component: () => import('@/views/overview')},
      {path: '/drive-log', name: 'driveLog', component: () => import('@/views/drivelog')},
      {path: '/boxes', name: 'boxes', component: () => import('@/views/boxes')},
      {path: '/users', name: 'Users', component: () => import('@/views/users')},
      {path: '/map', name: 'map', component: () => import('@/views/map')},
      {path: '/companies', name: 'Companies', component: () => import('@/views/companies')},
      {path: '/vehicles', name: 'Vehicles', component: () => import('@/views/vehicles')},
      {path: '/settings', name: 'Settings', component: () => import('@/views/settings')},
    ]
  },
  {path: '/login', meta: {guest: true}, name: 'login', component: () => import('@/views/login')},
  {path: '/register', meta: {guest: true}, name: 'register', component: () => import('@/views/register')},
  {path: '*', redirect: '/'}
];

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(item => item.meta.auth)) {
    if(localStorage.getItem('token') === null) {
      next({
        name: 'login',
        params: {returnUrl: to.fullPath}
      })
    }
  }
  if(to.matched.some(item => item.meta.guest)) {
    if(localStorage.getItem('token')) {
      next('/')
    }
  }
  next();
})

export {router};


