import InputField from "@/components/shared/InputField";
import AppButton from "@/components/shared/AppButton";
import AppTable from "@/components/shared/AppTable";
import AppIcon from "@/components/shared/AppIcon";
import AppModal from "@/components/shared/AppModal";

import Vue from "vue";

Vue.component('InputField', InputField);
Vue.component('AppButton', AppButton);
Vue.component('AppTable', AppTable);
Vue.component('AppButton', AppButton);
Vue.component('AppTable', AppTable);
Vue.component('AppIcon', AppIcon);
Vue.component('AppModal', AppModal);
