import axios from "axios";
import {storeTokensData} from "@/services/auth-service";
import authHttp from "@/http/auth";
import {router} from "@/router";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use((config) => {
  const token  = localStorage.getItem('token');
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.response.use((response) => response.data, onError);
async function onError(error) {
  if (error.response.status === 401) {
    try {
      const response = await authHttp.refreshToken(localStorage.getItem('refreshToken'));
      storeTokensData(response);
      //Repeat request
      const lastRequest = error.response.config;
      return await axios.request(lastRequest);
    } catch (e) {
      localStorage.clear();
      await router.push('/login');
      throw error;
    }
  }
  throw error;
}
//
// async function refreshToken() {
//   return axios.post("/login/refresh-token");
// }
