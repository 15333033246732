<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
  },
  created() {

    const token = localStorage.getItem('token');
    if(token) {
      this.$store.commit('setUser', token);
    }
  }
}
</script>

<style lang="scss">
#app {
  height: 100%;
}
html, body {
  overflow: auto;
}
</style>
