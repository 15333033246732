import Vue from 'vue';
import App from './App.vue';
import {router} from "@/router";

import "@/assets/style/css/bootstrap-grid.min.css";
import "@/plugins/element-ui";
import "@/plugins/vue-select";
import "@/plugins/components";
import "@/plugins/axios";
import "@/plugins/datepicker";
import "@/plugins/vue-pagination";
import "@/plugins/validations"

import "@/utils/directives";
import "@/utils/filters";

import {store} from "@/store";
import {userRolesTypes} from "@/utils/user-roles";
import {makeTheme} from "@/theme/theme-maker";

Vue.prototype.$userRoles = userRolesTypes;

Vue.config.productionTip = false;

makeTheme()

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
