export const userRolesTypes = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  RESELLER_ADMIN: 'RESELLER_ADMIN',
  COMPANY_ADMIN: 'COMPANY_ADMIN',
  SIMPLE_USER: 'SIMPLE_USER'
}

export const userRoles = [
  {
    name: 'Super admin',
    key: userRolesTypes.SUPER_ADMIN,
    translation: 'Super admin'
  },
  {
    name: 'Reseller admin',
    key: userRolesTypes.RESELLER_ADMIN,
    translation: 'Återförsäljare'
  },
  {
    name: 'Company admin',
    key: userRolesTypes.COMPANY_ADMIN,
    translation: 'Administratör'
  },
  {
    name: 'User',
    key: userRolesTypes.SIMPLE_USER,
    translation: 'Användare'
  }
]

export const userCanManageRoles = {
  [userRolesTypes.SUPER_ADMIN]: [userRolesTypes.RESELLER_ADMIN, userRolesTypes.COMPANY_ADMIN, userRolesTypes.SIMPLE_USER],
  [userRolesTypes.RESELLER_ADMIN]: [userRolesTypes.COMPANY_ADMIN, userRolesTypes.SIMPLE_USER],
  [userRolesTypes.COMPANY_ADMIN]: [userRolesTypes.COMPANY_ADMIN, userRolesTypes.SIMPLE_USER],
  [userRolesTypes.SIMPLE_USER]: [userRolesTypes.COMPANY_ADMIN, userRolesTypes.SIMPLE_USER],
}
