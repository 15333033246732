<template>
  <transition name="modal-fade">
    <div class="app-modal">
      <div class="app-modal__content">
        <div class="close-icon" @click="closeDialog">
          <app-icon name="cross" color=""/>
        </div>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
import AppIcon from "@/components/shared/AppIcon";
export default {
  components: {AppIcon},
  methods: {
    closeDialog() {
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss" scoped>
.app-modal {
  position: fixed;
  background: #00000073;
  width: 100%;
  z-index: 1001;
  height: 100%;
  left: 0;
  top: 0;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);

  &__content {
    //overflow-y: auto;
    position: relative;
    padding: 30px;
    background: #ffffff;
    border-radius: 20px;
    max-width: 700px;
    width: 100%;
    max-height: 80%;
    overflow-y: auto;
  }

  .close-icon {
    padding: 5px;
    position: absolute;
    top: 10px;
    right: 20px;
    border-radius: 50%;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);

    &:hover {
      cursor: pointer;
      background: #0000000d;
    }
  }
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease;
}
</style>
