import moment from "moment";

import Vue from 'vue';
import {unixToDateTime} from "@/utils/date-time.helper";
Vue.filter('thousandSpace', value => {
  if(value === 0) {
    return 0;
  }
  return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : '';
});

Vue.filter('duration', value => {
  let d = moment.duration(value, 'milliseconds');
  let hours = Math.floor(d.asHours());
  let mins = Math.floor(d.asMinutes()) - hours * 60;
  return `${hours}h${mins}m`
});

Vue.filter('unixToDate', value => {
  const date = new Date(value)
  return moment(date).format('YYYY-MM-DD HH:mm')
})

Vue.filter('unixDate', value => {
  return unixToDateTime(value);
})
