import axios from "axios";

export default ({
  login(username, password) {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    }

    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post('/authentication/login', params, {headers});
  },
  refreshToken(token) {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    const params = new URLSearchParams();
    params.append('refresh_token', token);

    return axios.post('/authentication/refresh_token', params, {headers});
  },

  signup(data) {
    return axios.post('/auth/signup', data)
  },

  sendResetEmail(email) {
    return axios.put(`/users/${email}/reset-password`)
  }

})
