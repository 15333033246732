(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory(require('vue2-datepicker')) :
    typeof define === 'function' && define.amd ? define(['vue2-datepicker'], factory) :
      (global = global || self, (global.DatePicker = global.DatePicker || {}, global.DatePicker.lang = global.DatePicker.lang || {}, global.DatePicker.lang.sv = factory(global.DatePicker)));
}(this, (function (DatePicker) { 'use strict';

  DatePicker = DatePicker && DatePicker.hasOwnProperty('default') ? DatePicker['default'] : DatePicker;

  function unwrapExports (x) {
    return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, 'default') ? x['default'] : x;
  }

  function createCommonjsModule(fn, module) {
    return module = { exports: {} }, fn(module, module.exports), module.exports;
  }

  var sv = createCommonjsModule(function (module, exports) {

    Object.defineProperty(exports, "__esModule", {
      value: true
    });
    exports["default"] = void 0;
    var locale = {
      months: ['Januari ', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
      monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
      weekdays: ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'],
      weekdaysShort: ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'],
      weekdaysMin: ['sö', 'må', 'ti', 'on', 'to', 'fr', 'lö'],
      firstDayOfWeek: 1,
      firstWeekContainsDate: 4
    };
    var _default = locale;
    exports["default"] = _default;
    module.exports = exports.default;
  });

  var sv$1 = unwrapExports(sv);

  var lang = {
    formatLocale: sv$1,
    yearFormat: 'YYYY',
    monthFormat: 'MMM',
    monthBeforeYear: true
  };
  DatePicker.locale('sv', lang);

  return lang;

})));
